<template lang="pug">
div()
  v-data-table.elevation-1(v-model="selected" :headers="headers" :items="$store.state.vehicles.devices.device_data" :items-per-page="10" item-key="name" show-select :single-select="false" :loading="false" )
    template( v-slot:item.name="{item}" :style="getOpacityStyle(item)")
      v-layout(row align-center  :style="getOpacityStyle(item)")
        div.mr-2()
          v-icon(:size="40") {{item.device_type==="MOBILE"?"stay_current_portrait":"rss_feed"}}
        v-layout(column)
          .caption.primary--text() {{item.name}}
          .caption.mb-n1() {{item.phone_number}}
          .smallText() {{item.imei}}
          .smallText.font-weight-bold() {{item.device_type}}
    
    template(v-slot:item.last_online_date="{item}")
      div(v-if="item.last_online_date"  :style="getOpacityStyle(item)")
        .timeText(v-if="item.last_online_date")
          | {{item.last_online_date |  moment("DD MMM YYYY &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp; hh:mm a")}}
        .caption
          timeago.caption.grey--text(:datetime="new Date(item.last_online_date)")
        .caption.font-weight-bold() odo: 
          span.primary--text() {{item.last_odometer}}

    template(v-slot:item.last_location_point="{item}")
      div( :style="getOpacityStyle(item)")
        .div(v-if="item.last_location_address") {{item.last_location_address}}
        .caption.grey--text(v-if="item.last_location_point") {{item.last_location_point.lon}},{{item.last_location_point.lat}} {{item.active}}
     

    template(v-slot:item.vehicle="{item}" )
      vehicle-column(v-if="item.vehicle" :vehicle="item.vehicle"  :style="getOpacityStyle(item)")

    template(v-slot:item.device_type="{item}")
      v-chip.mr-2(v-if="item.immobilizer" x-small) immobilizer
      v-chip.mr-2(v-if="item.beeper" x-small) beeper
      v-chip.mr-2(v-if="item.camera" x-small) camera
      v-chip.mr-2(v-if="item.fuel" x-small) fuel sensor

    template( v-slot:item.action="{ item }")
      v-layout(align-center justify-center )
        more-drop-down-menu(:items='getMenuItems(item)', @onClick='onClick', :theObject='item')

    template( v-slot:item.max_speed="{ item }")
      div(:style="getOpacityStyle(item)") {{item.max_speed}}
    
  
  
  v-dialog(v-model="showDialogCommand"  max-width="320")
    command-dialog(:item="selectedItem" @closeDialog="onCloseDialog")  
</template>
<script>
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
import ContactColumn from "@/modules/global/components/table/ContactColumn";
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import Status from "@/modules/global/components/others/Status";
import CommandDialog from "@/modules/vehicles/components/ui/CommandDialog";

export default {
  components: {
    MoreDropDownMenu,
    Status,
    CommandDialog,
    VehicleColumn,
    ContactColumn
  },
  data: () => ({
    showDialogCommand: false,
    selectedItem: {},
    selected: [],
    headers: [
      {
        text: "Name",
        align: "left",
        sortable: false,
        value: "name"
      },
      { text: "Last Online", value: "last_online_date", align: "left" },
      { text: "Last Location", value: "last_location_point", align: "left" },
      { text: "Vehicle", value: "vehicle", align: "left" },
      { text: "Max Speed", value: "max_speed", align: "center" },
      { text: "Options", value: "device_type", align: "left" },
      { text: "Actions", value: "action", sortable: false, align: "center" }
    ],


  }),



  methods: {
    getMenuItems: function(item) {
      let menuItems = [
        { title: "Command", icon: "phonelink_setup", action: "command" },
        { title: "Edit", icon: "edit", action: "edit" },

      ]

      if (item.active){
        menuItems.push({ title: "De-activate", icon: "link_off", action: "disable" })
      }else{
        menuItems.push( { title: "Activate", icon: "link", action: "enable" })
      }

     menuItems.push({ title: "Delete", icon: "delete", action: "delete" })
      return menuItems
    },

    getOpacityStyle: function(item) {
      return {opacity:item.active?1.0:0.3}
    },

    onClick: function(value) {
      switch (value.action) {
        case "edit":
          this.$store.commit(
            "vehicles/devices/setCurrentIndexByID",
            value.theObject.id
          );
          this.$router.push({
            name: "deviceForm",
            params: { vehicleID: value.theObject.id }
          }).catch(()=>{});
          break;
        case "disable":
          confirm("Disabling will stop recording of device data. You can allways re-enable") &&
          this.$store.dispatch(
              "vehicles/devices/disableDevice",
              value.theObject.id
          );
          break;
        case "enable":
          this.$store.dispatch(
              "vehicles/devices/enableDevice",
              value.theObject.id
          );
          break;
        case "delete":
          confirm("Are you sure you want to delete this track Device?") &&
            this.$store.dispatch(
              "vehicles/devices/deleteDevice",
              value.theObject.id
            );
          break;
        case "command":
          this.selectedItem = value.theObject;
          this.showDialogCommand = true;
          break;
      }
    },

    onCloseDialog() {
      this.showDialogCommand = false;
    }
  }
};
</script>
<style scoped lang="stylus">
.smallText {
  font-size: 9px;
}

.timeText {
  font-size: 13px;
  margin-bottom: -3px;
}

.responseStyle {
  background-color: #CCCCCC;
  border-radius: 10px;
}
</style>
