<template lang="pug">
  div()
    #mapPlace(:key="mapKey"  ref="mapPlace"  :style="{height: `${$store.state.main_height - 50}px`}")
</template>

<script>
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
export default {
  data: () => ({
    mapKey: 0,
    mapReady: false,
    markerList:[],
    placeMap: null,
    isLoading:true,
    mapLoaded: false,
  }),
  mounted() {
    this.init();
  },

  methods: {
    init: function(){
      this.mapKey += 1;
      this.mapReady = false;
      this.placeMap = null;
      this.mapLoaded = false;

      this.$nextTick(() => {
        this.createMap();
      })

    },
    createMap: function(){
      mapboxgl.accessToken =
          "pk.eyJ1IjoibXVnYW1iaWtpbWF0aGkiLCJhIjoiY2p0OWNjaGZxMDdsdjQ0cXZjZTRhZnA0aSJ9.3b3qa7uHHInxWL3TBiMsSQ";
      this.mapPlace = new mapboxgl.Map({
        container: "mapPlace",
        style: "mapbox://styles/mapbox/streets-v10",
        minzoom: 1.3,
        center: [36.76132069660187, -1.2985446867349744], // Nairobi
        zoom: 16
      });
      this.markerList = [];



      for (var i = 0; i < this.$store.state.place.place_data.length; i++) {


        let group = this.$store.getters["settings/groupSettings/getGroupByID"](this.$store.state.place.place_data[i].group_id) || {color:'#d70063'};
        let tempMarker = new mapboxgl.Marker({
          color: group.color,
          id: this.$store.state.place.place_data.id
        });
        tempMarker.setLngLat([
          this.$store.state.place.place_data[i].location.lon,
          this.$store.state.place.place_data[i].location.lat
        ]);

        tempMarker.getElement().dataset.id = this.$store.state.place.place_data[i].id;

        tempMarker.getElement().addEventListener('click', () => {
          // this.clickedMarker(this.$store.state.place.place_data[i]);
          this.clickedMarker(tempMarker.getElement().dataset.id);
        });


        let tempPopUp = new mapboxgl.Popup();

        tempPopUp.setHTML(
            `<span style="font-weight: bold"> ${this.$store.state.place.place_data[i].name} </span><br/><span>${this.$store.state.place.place_data[i].street??''}</span>`
        );
        tempMarker.popUp = tempPopUp;
        tempMarker.popUpText = `<span style="font-weight: bold"> ${this.$store.state.place.place_data[i].name} </span><br/><span>${this.$store.state.place.place_data[i].street??''}</span>`;
        tempMarker.setPopup(tempPopUp);


        this.markerList.forEach(element => {
          element.addTo(this.mapPlace);
          element.togglePopup();
        })

        this.markerList.push(tempMarker);
      }

      this.mapPlace.on("load", this.onLoadMap);
    },

    onLoadMap: function(){
      this.mapReady = true;
      this.isLoading = true;


      if (this.markerList.length > 0) {
        this.zoomExtendMarkers(this.markerList);
      }
    },

    zoomExtendMarkers: function(theArray) {
      if (theArray == undefined) return;
      let bbox;
      let tempArrayCoordinates = [];

      theArray.forEach(e => {
        tempArrayCoordinates.push([e.getLngLat().lng,e.getLngLat().lat])
      })

      if(tempArrayCoordinates.length == 0){
        return;
      }

      if(tempArrayCoordinates.length > 1){
        let line = turf.lineString(tempArrayCoordinates,{name:"lineFromMarkers"});
        bbox = turf.bbox(line);
        this.mapPlace.fitBounds(bbox, {
          padding: 40
        })
      }else{
        this.mapPlace.flyTo({
          center: tempArrayCoordinates[0],
          essential:true,
        })
      }
    },

    clickedMarker: function(thePlaceID){
      this.$router.push({
        name: "placeDetailsForm",
        params: {placeID: thePlaceID}
      }).catch(()=>{});
    }
  }

}
</script>

<style scoped lang="stylus">

</style>