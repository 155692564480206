<template lang="pug">
div(v-if='$store.state.vehicles.devices.currentIndex > -1')
  v-toolbar(dense, flat)
    v-toolbar-title Device Details
    v-spacer
  
  
  v-container.py-2
    v-alert(v-if="errors.length" :value="true" color="error" icon="warning") 
      div(v-for="e,index in errors" :key="index" v-text="e")

    v-layout(justify-end)
      v-btn.mx-2(small text color="grey" @click="cancel") cancel
      v-btn.mx-2(small color="primary" @click="save" :disabled="!valid") Save

  v-form(ref="form" v-model="valid" lazy-validation)
    v-container
      v-card
        v-container(grid-list-xl)
          form-divider DEVICE DETAILS {{id}}
          v-layout(row, wrap, justify-start)
            v-flex(xs12, md6, xl4)
              v-text-field(v-model='name', hint='Name of device', persistent-hint, label='Name')
            v-flex(xs12, md6, xl4)
              v-text-field(v-model='imei', hint='IMEI', persistent-hint, label='imei')
            v-flex(xs12, md6, xl4)
              v-text-field(v-model='phone_number', hint='Device MOBILE Number', persistent-hint, label='MOBILE')
            v-flex(xs12, md6, xl4)
              v-select( v-model='device_type' @change="onChangeDeviceType" :items="deviceTypesArray" label="Device Type" item-value="value" item-text="name")
            v-flex(xs6, md3, xl2)
              v-checkbox(v-model="immobilizer" label="Has Immobilizer")
            v-flex(xs6, md3, xl2)
              v-checkbox(v-model="beeper" label="Has Beeper")
            v-flex(xs6, md3, xl2)
              v-checkbox(v-model="camera" label="Has Camera")
            v-flex(xs6, md3, xl2)
              v-checkbox(v-model="fuel" label="Has Fuel Sensor")


          form-divider CONNECTED TO:
          v-layout(row, wrap, justify-start)
            v-flex(v-if="device_type!=='MOBILE'" xs12, md6, xl4)
              vehicle-auto-complete(v-model='vehicle_id' label="Select Vehicle" placeholder="Vehicle device is attached to")
            v-flex(v-if="device_type==='MOBILE'"  xs12, md6, xl4)
              contact-auto-complete(v-model='contact_id' label="Select Contact"  placeholder="MOBILE Phone device is attached to")
            v-flex(xs12, md6, xl4)
              v-text-field(v-model.number='max_speed', type="number" hint='Maximum Speed', persistent-hint, label='Maximum Speed')
            v-flex(xs12, md6, xl4)
              v-select(v-model="algorithm" :items="algorithmItems" item-text="name" item-value="value"  hint='Trip Tracking Method', persistent-hint, label='Trip Algorithm')



</template>
<script>
import VehicleAutoComplete from "@/modules/global/components/forms/VehicleAutoComplete";
import ContactAutoComplete from "@/modules/global/components/forms/ContactAutoComplete";

import { createHelpers } from "vuex-map-fields";
import FormDivider from "@/modules/global/components/forms/FormDivider";
const { mapFields } = createHelpers({
  getterType: "vehicles/devices/getDevice",
  mutationType: "vehicles/devices/updateDevice"
});
export default {
  components: {
    FormDivider,
    VehicleAutoComplete,
    ContactAutoComplete
  },
  data: () => ({
    valid: true,
    error: false,
    errors: [],
    algorithmItems:[
      {
        name:"Course",
        value:0,
      },
      {
        name:"Fine",
        value:1,
      },
      {
        name:"Ignition",
        value:2,
      }
    ],
    deviceTypesArray: [
      {
        name: "Teltonika OBDII Device",
        value: "FMB003"
      },
      {
        name: "Teltonika Tracker FMB920",
        value: "FMB920"
      },
      {
        name: "Teltonika Tracker FMB125",
        value: "FMB125"
      },
      {
        name: "Teltonika Tracker FMB130",
        value: "FMB130"
      },
      {
        name: "Teltonika Tracker FMB140",
        value: "FMB140"
      },
      {
        name: "Mobile Phone",
        value: "MOBILE"
      }
    ]
  }),
  methods: {
    onChangeDeviceType: function(value) {
      console.log(value === "MOBILE");
      if (value === "MOBILE") {
        this.vehicle_id = null;
        this.vehicle = null;
      } else {
        this.contact_id = null;
        this.contact = null;
      }
    },

    cancel: function() {
      // show the List Page
      this.$router.push({ name: "vehicles", params: { tabID: 4 } }).catch(()=>{});

      // refresh the List
      this.$store.dispatch("vehicles/devices/loadAllDevices");
    },

    save: function() {
      // check for errors
      this.errors = [];
      if (this.$refs.form.validate()) {
        if (this.device_type !== "MOBILE" && !this.vehicle_id) {
          this.errors.push("Vehicle Required");
        }

        if (!this.errors.length) {
          this.$store.dispatch("vehicles/devices/saveDevice").then(() => {
            this.$store.commit("showSnackBar", {
              color: "primary",
              message: `Device "${this.name}" is saved`
            });
            // show the List Page
            this.$router.push({ name: "vehicles", params: { tabID: 4 } }).catch(()=>{});
            // refresh the List
            this.$store.dispatch("vehicles/devices/loadAllDevices");
          });
        }
      }
    }
  },
  computed: {
    ...mapFields([
      "id",
      "name",
      "imei",
      "phone_number",
      "device_type",
      "vehicle_id",
      "contact_id",
      "vehicle",
      "max_speed",
      "contact",
      "immobilizer",
      "beeper",
      "camera",
      "algorithm",
      "fuel"
    ])
  },

  mounted() {
    console.log(this.$route.params.vehicleID);
    console.log(this.$store.state.vehicles.devices.currentIndex);
    if (this.$store.state.vehicles.devices.currentIndex == -1) {
      // Load
      this.$store.dispatch(
        "vehicles/devices/fetchDevice",
        this.$route.params.vehicleID
      );
    }
  }
};
</script>
<style scoped lang="stylus"></style>
